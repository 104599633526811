@import "../../styles/variables";

.container {

  @media only screen and (min-width: 551px) {
    display: none;
  }

  .sideDrawer {
    height: 100%;
    background-color: $primaryColor;
    box-shadow: -7px 0 9px 3px gray;
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;

    @media (min-width: 769px) {
      display: none;
    }

    .items {

    }

    > ul {
      height: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > li {
        margin-bottom: 20px;
        font-size: 16px;

        > a {
          text-decoration: none;
          color: $grayColor;

          &:hover,
          &:active {
            color: $whiteColor;
          }
        }
      }
    }
  }

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $grayColor;
    opacity: 50%;
    z-index: 100;
  }
}

