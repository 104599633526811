@import "../../styles/variables";
.toggleButton {
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 25px;
  padding-left: 20px;
  color: $grayColor;

  &:hover {
    color: $whiteColor;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 550px) {
    display: none;
  }
}

