@import "../../styles/variables";

.profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $secondaryColor;
  height: 100%;
  font-size: 15px;
  font-family: Abel, serif;
  color: $primaryColor;
  padding: 30px;

  @media only screen and (max-width: 415px) {
    flex-direction: column;
    font-size: 18px;
  }

  .imgContainer {
    display: flex;
    justify-content: center;
    padding: 20px;

    .profileImage {
      display: flex;
      width: 200px;
      height: 200px;
    }
  }

  .name,
  .position {
    font-family: Abel, serif;
    margin: 0;
  }

  .nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 415px) {
      text-align: center;
    }

    .name {
      color: $primaryColor;
    }

    .position {
      font-size: 20px;
      margin-bottom: 10px;
      margin-left: 0;

      @media only screen and (max-width: 415px) {
        font-size: 24px;
      }
    }
  }

  .contactsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 415px) {
      text-align: left;
      margin: auto;
    }

    .contacts {
      padding: 10px;
      margin: 0;
      font-size: 15px;

      @media only screen and (max-width: 415px) {
        font-size: 18px;
      }

      .icon {
        font-size: 20px;
        margin-right: 10px;
        color: $primaryColor;

        @media only screen and (max-width: 415px) {
          font-size: 24px;
        }
      }

      .link {
        color: $primaryColor;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

}
