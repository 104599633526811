.job {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  .position, .companyName, .duration {
    margin: 0;
    line-height: 1.4;
    font-family: Abel, serif;
  }

  .position {
    font-weight: bold;
    font-size: 18px;

    @media only screen and (max-width: 415px) {
      font-size: 20px;
    }
  }

  .companyName {
    font-weight: normal;
    font-size: 15px;

    @media only screen and (max-width: 415px) {
      font-size: 18px;
    }
  }

  .companyName {
    text-transform: uppercase;
    font-size: 15px;

    @media only screen and (max-width: 415px) {
      font-size: 18px;
    }
  }

  .duration {
    font-style: italic;
    margin-top: 0;
  }
}

.responsibilitiesTitle {
  font-size: 15px;
  margin-top: 10px;
  font-family: Abel, serif;

  @media only screen and (max-width: 415px) {
    font-size: 18px;
  }
}

.responsibilities {
  font-size: 15px;
  font-family: Abel, serif;

  @media only screen and (max-width: 415px) {
    font-size: 18px;
  }
}
