@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  padding-right: 170px;

  @media only screen and (max-width: 415px) {
    padding-right: 0;
  }

  .experience {
    font-family: Abel, serif;
    color: $primaryColor;
    font-weight: bolder;
  }
}
