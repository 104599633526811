@import "../../styles/variables";

.content {
  display: flex;
  flex-direction: row;
  padding: 20px 60px;
  background-color: $secondaryColor;

  @media only screen and (max-width: 415px) {
    flex-direction: column;
  }

  > :not(:last-child) {
    margin-bottom: 20px;
  }
}
