@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  background-color: $secondaryColor;

  .skills {
    font-family: Abel, serif;
    color: $primaryColor;
    font-weight: bolder;
  }

  .group {
    font-family: Abel, serif;
    margin: 0;
    line-height: 1.4;
    font-weight: bold;
    font-size: 18px;

    @media only screen and (max-width: 415px) {
      font-size: 20px;
    }
  }

  .list {
    list-style: none;
    padding-left: 20px;
    font-size: 15px;

    @media only screen and (max-width: 415px) {
      font-size: 18px;
    }
  }
}


