@import "../../styles/variables";

.main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: $secondaryColor;

  @media only screen and (max-width: 415px) {
    flex-direction: column;
  }

  .bannerContainer {
    display: flex;
    background-color: $primaryColor;
    max-width: 930px;
    flex-direction: column;
    padding: 20px;
  }

  .projects {
    margin: auto;
    display: flex;
    max-width: 780px;
    padding: 20px;

    > ul,
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;
      padding: 10px;
      font-weight: 300;
      font-size: 15px;
      font-family: Abel, serif;
      color: $whiteColor;

      > h2 {
        font-size: 40px;
        padding: 10px;
        color: $primaryColor;
      }

      > a {
        font-size: 23px;
        color: $whiteColor;
        cursor: pointer;
      }

      > li {
        color: $whiteColor;
      }
    }
  }
}
