@import "../../styles/variables";

.container {
  padding: 20px 60px;
  background-color: $secondaryColor;

  @media only screen and (max-width: 415px) {
    padding: 20px 60px 20px 60px;
  }

  .title {
    font-family: Abel, serif;
    color: $primaryColor;
    font-weight: bolder;
  }

  .headline {
    font-size: 15px;

    @media only screen and (max-width: 415px) {
      font-size: 18px;
    }
  }
}
