.resume {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Abel, serif;
  padding-top: 60px;


  @media only screen and (max-width: 415px) {
    flex-direction: column;
  }
}
