@import "../../styles/variables";

.home {
  display: flex;
  flex-direction: column;
  color: $whiteColor;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .container {
    //margin: auto;

    @media only screen and (max-width: 415px) {
      padding: 20px;
    }

    .name {
      margin-bottom: 0.5px;
    }

    .typewriter {
      flex-direction: column;
      display: -webkit-inline-box;

      @media only screen and (max-width: 415px) {
        font-size: 25px;
      }

      .type {
        padding-right: 5px;
      }

    }

    .bar {
      background-color: $whiteColor;
      height: 3px;
      flex-grow: 1;
      width: 100%;

      @media only screen and (max-width: 415px) {
        flex-grow: 0;
      }
    }

    .contact {
      margin-top: 16px;
      display: flex;
      flex-direction: row;

      > p {
        margin: 0;
      }
    }

    .icon {
      font-size: 40px;
      margin-right: 20px;
      color: $grayColor;

      @media only screen and (max-width: 415px) {
        font-size: 35px;
      }

      .item {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
